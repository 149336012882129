<script lang="ts">
  import PageWrapper from "@src/components/PageWrapper.svelte";
  import Row from "@src/components/Row.svelte";
</script>

<PageWrapper fillerColor="gray">
  <Row color="green">
    <h1>Matkasäännöt</h1>

    <ul>
      <li>
        Matkoilla joille lähtee vain yksi bussi, on ikärajana 16 vuotta, jos
        matkaan lähtee enemmän kuin yksi bussi on "ykkösbussi" K18. Useamman
        bussin matkoilla, voidaan järjestää myös perheille suunnattu bussi.
      </li>

      <li>
        Alle 18 vuotiaalla on oltava kirjallinen huoltajan lupa matkalle
        lähdettäessä ja alle 16 vuotiaat vain huoltajan seurassa,
        ilmoittautuessasi matkalle, ilmoitathan mikäli sinulla on huollettava
        mukanasi.
      </li>

      <li>
        Varmistathan omalla käytökselläsi myös muiden lähtijöiden mukavan
        ilmapiirin matkoillamme.
      </li>

      <li>Ethän kuvaa ketään ilman lupaa.</li>

      <li>
        Sovittuja lähtöaikoja noudatetaan sekä meno-, että paluumatkoilla.
        Järjestäjä ei vastaa matkalle osallistuvien kotimatkasta, mikäli hän
        joutuu hankaluuksiin virkavallan kanssa.
      </li>

      <li>
        Matkojen ennakkoilmoittautuminen on sitova. Mahdollisista peruutuksista
        on ilmoitettava viimeistään 48h ennen matkalle lähtöä. Mikäli matkaa ei
        peruta annetun ohjeen mukaisesti, on matkanjärjestäjällä oikeus periä
        hinta kokonaisuudessaan ilmoittautuneelta.
      </li>

      <li>Ilmoittautumalla matkalle sitoudut noudattamaan jokaista sääntöä.</li>

      <li>
        Mikäli matkalle lähtijä ei noudata annettuja sääntöjä, on
        matkanjärjestäjällä oikeus määrätä sääntöjä rikkoneelle henkilölle
        porttikielto tapauskohtaisesti.
      </li>

      <li>
        Jos matkoillamme esiintyy ilkivaltaa, joka kohdistuu matkoilla
        käytettyihin kulkuneuvoihin, tulemme perimään ilkivallasta muodostuneet
        kulut ilkivallan tekijöiltä suoraan. Lisäksi matkan aikana käytettyjen
        kulkuneuvojen omistajilla on myös oikeus periä kulkuneuvoille
        aiheutuneiden vahinkojen kuluja suoraan vahinkojen aiheuttajalta.
      </li>

      <li>
        Emme korvaa kannattajamatkoilla hukkuneita tai hajonneita tavaroita.
      </li>
    </ul>
  </Row>
  <Row color="gray" filler />
</PageWrapper>

<style>
  li {
    margin-bottom: 1rem;
    line-height: 1.5;
  }
</style>
