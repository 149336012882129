<script lang="ts">
  import PageWrapper from "@src/components/PageWrapper.svelte";
  import Row from "@src/components/Row.svelte";
</script>

<svelte:head>
  <title>Osasto 41 - Sivua ei löytynyt</title>
</svelte:head>
<PageWrapper fillerColor="gray">
  <Row color="green">
    <p>TODO: 404 sivu</p>
  </Row>
  <Row color="gray" filler />
</PageWrapper>
