<span>
  <slot />
</span>

<style>
  span {
    text-transform: uppercase;
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
    padding: 0.2rem 0.5rem;
    font-size: 0.8rem;
    border-radius: 3px;
    background-color: var(--chip-background-color);
    color: var(--chip-text-color);
    white-space: nowrap;
  }
</style>
