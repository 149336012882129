<script lang="ts">
  import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
  import { onMount } from "svelte";

  import HamburgerIcon from "@src/icons/HamburgerIcon.svelte";
  import NavLink from "./NavLink.svelte";
  import Row from "./Row.svelte";

  export let items: {
    to: string;
    label: string;
    isExternal?: boolean;
  }[];
  export let title = "Osasto 41";

  let showMobileMenu = false;
  let scrollElement: HTMLElement;

  $: {
    if (showMobileMenu) {
      scrollElement.scrollTop = 0;
      disableBodyScroll(scrollElement);
    } else {
      clearAllBodyScrollLocks();
    }
  }

  onMount(() => {
    window
      .matchMedia("(max-width: 45em)")
      .addEventListener("change", (event) => {
        if (!event.matches) {
          showMobileMenu = false;
        }
      });

    window.addEventListener("pushstate", () => {
      showMobileMenu = false;
    });
  });

  function toggleMobileMenu() {
    showMobileMenu = !showMobileMenu;
  }
</script>

<div class="wrapper">
  <Row color="black" nopadding>
    <nav>
      <div class="logo">
        <NavLink to="/">
          <img src="/images/logo.png" alt="Etusivu" />
          <span class="logo-text">{title}</span>
        </NavLink>
      </div>
      {#if items.length}
        <div
          class="mobile-overlay"
          class:visible={showMobileMenu}
          on:click={toggleMobileMenu}
        />
        <ul class={showMobileMenu ? "visible" : ""} bind:this={scrollElement}>
          {#each items as { to, label, isExternal }}
            <li>
              <NavLink
                {to}
                {isExternal}
                on:click={() => (showMobileMenu = false)}
              >
                {label}
              </NavLink>
            </li>
          {/each}
        </ul>
        <div on:click={toggleMobileMenu} class="menu-toggle">
          <HamburgerIcon state={showMobileMenu ? "close" : "open"} />
        </div>
      {/if}
    </nav>
  </Row>
</div>

<style>
  .wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    overflow: hidden;
    max-width: 100vw;
  }

  nav {
    position: relative;
    height: var(--navbar-height);
    background: #000;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.5rem;
    box-sizing: border-box;
  }

  .logo {
    display: inline-block;
    margin-right: auto;
    height: 100%;
  }

  .logo img {
    box-sizing: border-box;
    width: 3rem;
  }

  .logo :global(a) {
    text-decoration: none;
  }

  .logo-text {
    text-transform: uppercase;
    margin-left: 1rem;
    font-size: 2.5rem;
    color: var(--white);
    font-family: Damaged, Impact, sans-serif;
  }

  ul {
    height: 100%;
  }

  li {
    display: inline-flex;
    list-style-type: none;
    position: relative;
    margin: 0 0.6rem;
    height: 100%;
  }

  li :global(svg) {
    height: 16px;
  }

  .mobile-overlay {
    visibility: hidden;
    opacity: 0;
    transition:
      visibility 0s,
      opacity 0.2s linear,
      backdrop-filter 0.2s linear;
  }

  .menu-toggle {
    fill: #fff;
    display: none;
    position: absolute;
    right: 0.5rem;
  }

  @media only screen and (max-width: 45em) {
    .menu-toggle {
      display: inline-block;
    }
    ul {
      position: fixed;
      top: 0;
      right: -100%;
      height: fill-available;
      max-width: var(--mobile-menu-max-width);
      width: var(--mobile-menu-width);
      background: #000;
      margin: 0;
      padding: 0;
      padding-top: 3rem;
      overflow: auto;
      transition: right 0.3s ease;
    }

    ul.visible {
      right: 0;
      box-shadow: -0.05rem 0 2rem #000;
    }

    li {
      display: block;
      font-size: 1.5rem;
      margin: 0;
      height: auto;
    }

    li :global(svg) {
      height: 32px;
    }

    li :global(a) {
      display: block;
      padding: 1.5rem;
      padding-left: 2rem;
    }

    .mobile-overlay.visible {
      visibility: visible;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(0.3rem);
      opacity: 1;
    }
  }
</style>
