<script lang="ts">
  import FacebookIcon from "@src/icons/FacebookIcon.svelte";
  import InstagramIcon from "@src/icons/InstagramIcon.svelte";
  import TwitterIcon from "@src/icons/TwitterIcon.svelte";
</script>

<div class="container">
  <a
    href="https://www.facebook.com/Osasto41"
    target="_blank"
    rel="noopener noreferrer"
  >
    <FacebookIcon />
  </a>
  <a
    href="https://twitter.com/osasto41"
    target="_blank"
    rel="noopener noreferrer"
  >
    <TwitterIcon />
  </a>
  <a
    href="https://www.instagram.com/osasto41"
    target="_blank"
    rel="noopener noreferrer"
  >
    <InstagramIcon />
  </a>
</div>

<style>
  .container {
    margin-left: 2rem;
  }

  .container :global(svg) {
    width: 1.5rem;
    margin: 0.8rem;
    fill: #ccc;
  }

  .container :global(svg:hover) {
    fill: #fff;
  }
</style>
