<script lang="ts">
  import Row from "@src/components/Row.svelte";
  import PageWrapper from "@src/components/PageWrapper.svelte";
  import PinnedTripsSection from "./PinnedTripsSection.svelte";
  import NewsSection from "./NewsSection.svelte";
</script>

<svelte:head>
  <title>Osasto 41</title>
</svelte:head>
<PageWrapper fillerColor="gray">
  <Row color="green">
    <h1>Seuraavat matkat</h1>
    <PinnedTripsSection />
  </Row>
  <Row color="gray">
    <h1>Uutiset</h1>
    <NewsSection />
  </Row>
  <Row color="gray" filler />
</PageWrapper>
