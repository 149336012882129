<script>
  import PageWrapper from "@src/components/PageWrapper.svelte";
  import Row from "@src/components/Row.svelte";
</script>

<svelte:head>
  <title>Osasto 41 - Jäsenedut</title>
</svelte:head>
<PageWrapper fillerColor="gray">
  <Row color="green">
    <h1>Jäsenedut kaudella 2024-2025</h1>
    <ul>
      <li>
        <strong>Kannattajamatkat</strong> alennettuun hintaan
      </li>
      <li>
        <strong>Ilves-kaupasta</strong> vähintään 10€ ostoksista
        <strong>-15%</strong>
        <ul>
          <li>Ei koske replica-, GI- eikä GW-paitoja</li>
        </ul>
      </li>
      <li>
        <strong>Shooters:</strong> 1€ hanoista / vaihtuvat tarjoukset
      </li>
      <li>
        <strong>Schnitzelhaus:</strong> 1€ schnitzeleistä
      </li>
      <li>
        <strong>Hierontamankeli:</strong> -10% kertakäynnistä
      </li>
    </ul>
    <p>Sivua päivitetään, kun etuja varmistuu lisää.</p>
  </Row>
  <Row color="gray" filler />
</PageWrapper>

<style>
  ul {
    line-height: 1.5;
  }

  li {
    margin: 1rem;
  }
</style>
